<template>
    <div class="card">
        <header class="card-header">
            <p class="card-header-title">
                Configuración Campo<b-tag
                    v-if="form.id !== 0"
                    class="ml-2"
                    :type="campoDatoLaboral.activo ? 'is-success' : 'is-danger'"
                    >{{
                        campoDatoLaboral.activo ? 'Activo' : 'No Activo'
                    }}</b-tag
                >
                <b-tag v-else class="ml-2" type="is-info">
                    <b>NUEVO</b>
                </b-tag>
            </p>
            <b-dropdown
                aria-role="list"
                class="card-header-icon"
                position="is-bottom-left"
            >
                <template #trigger>
                    <b-icon icon="menu-down" />
                </template>

                <b-dropdown-item
                    v-if="campoDatoLaboral.activo == true"
                    aria-role="listitem"
                    @click="toggleActivo(false)"
                >
                    Deshabilitar Campo
                </b-dropdown-item>
                <b-dropdown-item
                    v-if="campoDatoLaboral.activo == false"
                    aria-role="listitem"
                    @click="toggleActivo(true)"
                >
                    Habilitar Campo
                </b-dropdown-item>
            </b-dropdown>
        </header>
        <div class="card-content">
            <b-loading :is-full-page="false" v-model="isLoadingSelected"></b-loading>
            <form @submit.prevent="handleSave">
                <b-field label="Nombre" label-position="on-border">
                    <b-input v-model="form.descripcion" required />
                </b-field>

                <b-field>
                    <b-button
                        icon-left="plus"
                        type="is-success"
                        size="is-small"
                        @click="addOption"
                    >
                        Agregar Opción
                    </b-button>
                </b-field>

                <b-table class="mb-2" :data="form.opciones">
                    <b-table-column label="Opciones" v-slot="props">
                        {{ props.row }}
                    </b-table-column>
                    <b-table-column label="Acciones" v-slot="props">
                        <a @click.prevent="deleteOption(props.row)">
                            <b-icon type="is-danger" icon="delete" />
                        </a>
                    </b-table-column>

                    <template #empty>
                        <div class="p-3 is-flex is-justify-content-center">
                            <p>
                                Este campo es <b>libre</b>, no tiene opciones
                                asignadas
                            </p>
                        </div>
                    </template>
                </b-table>

                <b-message type="is-dark-light">
                    <div class="content">
                        <h6>Vista Previa:</h6>
                        <b-field
                            :label="form.descripcion"
                            label-position="on-border"
                        >
                            <b-select
                                v-if="form.opciones && form.opciones.length > 0"
                                expanded
                            >
                                <option
                                    v-for="opcion of form.opciones"
                                    :value="opcion"
                                    :key="opcion"
                                >
                                    {{ opcion }}
                                </option>
                            </b-select>
                            <b-input v-else />
                        </b-field>
                    </div>
                </b-message>

                <b-button
                    type="is-primary"
                    native-type="submit"
                    :loading="isLoading"
                    :disabled="form.descripcion === ''"
                    expanded
                >
                    {{ form.id === 0 ? 'Guardar' : 'Actualizar' }}
                </b-button>
            </form>
        </div>
    </div>
</template>

<script>
import Message from '@/utils/Message';
export default {
    data() {
        return {
            isLoading: false,
            form: {
                id: 0,
                descripcion: '',
                opciones: [],
            },
        };
    },
    mounted() {
        //
    },
    computed: {
        campoDatoLaboral() {
            return this.$store.state.camposDatosLaborales.selected;
        },
        isLoadingSelected() {
            return this.$store.state.camposDatosLaborales.isLoadingSelected;
        }
    },
    watch: {
        campoDatoLaboral: function (value) {
            this.form.id = value?.id ?? 0;
            this.form.descripcion = value?.descripcion ?? '';
            this.form.opciones = value?.opciones ?? [];
        },
    },
    methods: {
        async fetchCamposDatosLaborales() {
            await this.$store.dispatch('camposDatosLaborales/get');
        },
        async handleSave() {
            this.isLoading = true;
            try {
                const action = !this.form.id
                    ? 'camposDatosLaborales/create'
                    : 'camposDatosLaborales/update';
                const { message, data } = await this.$store.dispatch(
                    action,
                    this.form
                );

                Message.showSuccessMessage(this, message);

                if (this.form.id === 0) {
                    this.clearForm();
                    await this.fetchCamposDatosLaborales();
                }
            } catch (err) {
                Message.showErrorMessage(this.err.message);
            } finally {
                this.isLoading = false;
            }
        },
        clearForm() {
            this.form.id = 0;
            this.form.descripcion = '';
            this.form.opciones = [];
        },
        deleteOption(opcion) {
            this.$buefy.dialog.confirm({
                title: 'Eliminar opción',
                message: '¿Está seguro que desea <b>eliminar</b> esta opción?',
                confirmText: 'Eliminar',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => {
                    const index = this.form.opciones.findIndex(
                        (_opcion) => _opcion === opcion
                    );
                    this.form.opciones.splice(index, 1);
                    this.$buefy.toast.open('Opción eliminada!');
                },
            });
        },
        addOption() {
            this.$buefy.dialog.prompt({
                message: 'Agregar opción',
                inputAttrs: {
                    type: 'text',
                    placeholder: 'Nombre de la opción',
                    value: '',
                },
                confirmText: 'Send',
                trapFocus: true,
                closeOnConfirm: false,
                onConfirm: (value, { close }) => {
                    if (!value) {
                        this.$buefy.toast.open(`La opción esta en blanco o es inválida!`);
                        return;
                    }

                    const repeat = this.form.opciones.find(_opcion => _opcion.toUpperCase() === value.toUpperCase());

                    if (repeat) {
                        this.$buefy.toast.open(`La opción esta repetida!`);
                        return;
                    }

                    this.form.opciones.push(value);
                    this.$buefy.toast.open(`La opción fue agregada correctamente!`);
                    close();
                },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
</style>