<template>
    <ul
        v-if="hasData"
        class="block-list has-radius is-primary is-centered empresa-datos-laborales__list"
    >
        <li
            :class="['empresa-datos__list--item', campoId == campo.id ? 'selected is-outlined' : '']"
            v-for="campo of camposDatosLaborales"
            :key="campo.id"
            @click="campoId != campo.id ? selectCampoLaboral(campo.id) : null"
        >
            {{ campo.descripcion }}
        </li>
    </ul>
    <div
        v-else
        class="empresa-datos-laborales__list is-align-items-center"
    >
        <slot name="empty"></slot>
    </div>
</template>

<script>
export default {
    mounted() {
        this.fetchCamposDatosLaborales();
    },
    updated() {
        // this.fetchCamposDatosLaborales();
    },
    computed: {
        hasData() {
            return this.camposDatosLaborales.length > 0;
        },
        camposDatosLaborales() {
            return this.$store.state.camposDatosLaborales.data;
        },
        campoId() {
            return this.$route.query.campo_id ?? null;
        }
    },
    methods: {
        async fetchCamposDatosLaborales() {
            await this.$store.dispatch('camposDatosLaborales/get');
        },
        selectCampoLaboral(campo_id) {
            this.$router.push({ name: 'admin-empresa-datos-datos-laborales', query: { campo_id } });
            this.$emit('change-campo-id', campo_id);
        }
    },
};
</script>

<style lang="scss" scoped>
.empresa-datos__list--item {
    cursor: pointer;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;

    &.selected {
        transform: translateX(-0.5rem);
        cursor: auto;
    }
}

.empresa-datos__list--item:hover {
    transform: translateX(-0.5rem);
}
</style>