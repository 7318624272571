<template>
    <div>
        <div class="content">
            <h2 class="has-text-primary-dark">Datos Laborales</h2>
        </div>
        <div class="columns">
            <div class="column is-one-quarter is-relative">
                <b-loading :is-full-page="false" v-model="isLoading"></b-loading>
                <div class="is-flex mb-3">
                    <div style="width: 50%">
                        <b-button
                            expanded
                            icon-left="arrow-left"
                            tag="router-link"
                            to="/a/empresa/datos"
                        >
                            Atrás
                        </b-button>
                    </div>
                    <div style="width: 50%">
                        <b-button
                            expanded
                            icon-left="plus"
                            tag="a"
                            href="/a/empresa/datos/datos-laborales"
                        >
                            Agregar
                        </b-button>
                    </div>
                </div>
                <b-collapse :open="isOpen" position="is-bottom" aria-id="contentIdForListaDatosLaborales">
                    <template #trigger="props">
                        <a v-show="!isOpen" aria-controls="contentIdForListaDatosLaborales">
                            <b-icon :icon="!props.open ? 'menu-down' : 'menu-up'"></b-icon>
                            {{ !props.open ? 'Mostrar opciones' : 'Ocultar' }}
                        </a>
                    </template>
                    <!-- Collapse Content -->
                    <empresa-lista-datos-laborales
                        @change-campo-id="fetchCampoLaboral"
                    >
                        <template #empty>
                            <div class="box is-flex is-align-items-center is-justify-content-center" style="height: 100%;">
                                <div class="level-item has-text-centered">
                                    <div>
                                        <p class="heading has-text-primary">No hay registros</p>
                                        <p class="title">
                                            <b-icon
                                                size="is-large"
                                                icon="archive-outline"
                                                type="is-primary"
                                            />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </empresa-lista-datos-laborales>
                </b-collapse>
            </div>
            <div class="column">
                <campos-configuracion />
            </div>
        </div>
    </div>
</template>

<script>
import Message from '@/utils/Message';
import EmpresaListaDatosLaborales from '@/components/Empresa/EmpresaListaDatosLaborales.vue';
import CamposConfiguracion from '@/components/Campos/CamposConfiguracion.vue';

export default {
    data() {
        return {
            windowWidth: window.innerWidth,
        };
    },
    components: {
        EmpresaListaDatosLaborales,
        CamposConfiguracion,
    },
    computed: {
        isOpen() {
            return this.windowWidth >= 768;
        },
        isLoading() {
            return this.$store.state.camposDatosLaborales.isLoading;
        }
    },
    mounted() {
        window.onresize = () => {
            this.windowWidth = window.innerWidth
        }

        const { campo_id } = this.$route.query;
        if (campo_id) {
            this.fetchCampoLaboral(campo_id);
        }
    },
    methods: {
        async fetchCampoLaboral(id) {
            try {
                await this.$store.dispatch('camposDatosLaborales/show', id);
            } catch (err) {
                Message.showErrorMessage(this, err.message);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
    .empresa-datos-laborales {
        &__list {
            height: 70vh;
            overflow: auto;
        }
    }
</style>
